import React, { useCallback, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import MainLayout from "../components/MainLayout";
import Carousel from "react-elastic-carousel";
import Testimonial from "../components/Testimonial/Testimonial";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from "gatsby";

interface CaseStudyProps {}

const caseStudyMainImage = "/images/showcase/feature-interactivity.png";
const caseStudyCompanyLogo = "images/logos/samsung.png";
const testimonials = [
  {
    title: "Scaling with HelloAR!",
    description:
      "HelloAR’s 360 immersive photography has added a new dimension to online used car sales. The immersive catalogue has led to happier customers making quicker purchasing decisions. Look forward to scaling this up with HelloAR!",
    name: "Naveen Sarawgi",
    designation: "CEO, Gigacars",
    profileWebp: "../../images/testimonials/naveen-gigacars1.webp",
    profileJpg: "../../images/testimonials/naveen-gigacars.jpg",
  },
  {
    title: "Exicting shopping experience",
    description:
      "When it comes to shopping for handicrafts, customers want to gauge every minute detail possible. With HelloAR, we're pretty close to enabling the same, thus making the entire shopping experience more exciting and thoughtful at the same time.",
    name: "Akansha",
    designation: "CE0, Craft Maestros",
    profileWebp: "../../images/testimonials/akansha-craftmaestro1.webp",
    profileJpg: "../../images/testimonials/akansha-craftmaestro.jpg",
  },
  {
    title: "Amazing platform",
    description:
      "HelloAR is an amazing platform. HelloAR's 3D images have aced up our ecommerce & engagement game. Also, the team is very helpful and nice.",
    name: "Aishwarya",
    designation: "Codesustain",
    profileWebp: "../../images/testimonials/pratima-codesustain1.webp",
    profileJpg: "../../images/testimonials/pratima-codesustain.jpg",
  },
  {
    title: "Products look exceptionally good",
    description:
      "Fantastic job on the chairs! All are looking amazing. Products look exceptionally good. Very life like. Good job team!",
    name: "Kiran",
    designation: "Featherlite",
    profileWebp: "../../images/testimonials/kiran-featherlite2.webp",
    profileJpg: "../../images/testimonials/kiran-featherlite.webp",
  },
];

const CaseStudy = () => {
  const carouselRef = useRef<any | null>(null);
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const disableNext = testimonialIndex === testimonials.length - 1;
  const disablePrevious = testimonialIndex === 0;
  const handleNext = useCallback(() => {
    carouselRef.current?.slideNext();
    setTestimonialIndex((prev) => prev + 1);
  }, []);
  const handlePrevious = useCallback(() => {
    carouselRef.current?.slidePrev();
    setTestimonialIndex((prev) => prev - 1);
  }, []);

  return (
    <MainLayout hideFooter width={0}>
      <div className="case-study-wrapper bg-white pointer-events-all">
        <Container>
          <div className="case-study-intro d-flex">
            <img src={caseStudyMainImage} alt="case-study-image" />
            <div className="case-study-intro-right">
              <img src={caseStudyCompanyLogo} alt="case-study-company-logo" />
              <h2>
                A viral experience zone showing off home appliances and
                technology
              </h2>

              <div>
                <h6 className="text-uppercase">What we did?</h6>
                <p>
                  We build a virtual experience zone showing off products like a
                  TV, refrigerator or washing machine.
                </p>
              </div>
              <div>
                <h6 className="text-uppercase">How are the benefiting?</h6>
                <p>
                  Samsung can now let their users experience their products not
                  only through images and videos but through immersive
                  experience. This make it incredibly easy for them to visualise
                  how the product work and how they will look in their own
                  space.
                </p>
              </div>
              <div style={{ width: "max-content" }}>
                <Link to="/contact-us">
                  <button className="button-primary w-100 mb-3">
                    Book Demo
                  </button>
                </Link>
                <Link to="/case-studies">
                  <button className="button-secondary d-flex align-items-center">
                    <div className="mr-2">See more cases</div>
                    <AiOutlineArrowRight />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="testimonial-wrapper mt-5">
            <h1>Testimonials</h1>
            <div className="testimonial-container d-flex">
              <div className="testimonial-list flex-grow-1">
                <TestimonialCarouselMemo ref={carouselRef} />
              </div>
              <div className="testimonial-slide-wrapper d-flex align-items-center">
                <button onClick={handlePrevious} disabled={disablePrevious}>
                  <BsChevronLeft size={20} />
                </button>
                <div className="testimonial-slide-text">Slide</div>
                <button onClick={handleNext} disabled={disableNext}>
                  <BsChevronRight size={20} />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
};

interface TestimonialCarouselProps {}
const TestimonialCarousel = React.forwardRef<any, TestimonialCarouselProps>(
  (_, ref) => {
    return (
      <Carousel
        initialActiveIndex={0}
        renderPagination={() => <></>}
        itemPadding={[]}
        transitionMs={200}
        ref={ref}
        breakPoints={[
          { width: 320, itemsToShow: 1, itemsToScroll: 1 },
          { width: 620, itemsToShow: 1, itemsToScroll: 1 },
        ]}
        showArrows={false}
      >
        {testimonials.map((testimonial) => (
          <Testimonial
            {...testimonial}
            customerPhoto={testimonial.profileJpg}
          />
        ))}
      </Carousel>
    );
  }
);

const TestimonialCarouselMemo = React.memo(TestimonialCarousel);

CaseStudy.Layout = MainLayout;

export default CaseStudy;
